import React from "react";
import { styled, Stack, Typography } from "@mui/material";
import { useSpring } from "@react-spring/web";
import Typewriter from "typewriter-effect";
import { useTheme } from "layouts/theme/ThemeContext";
import { useTranslation } from "react-i18next";

const HeaderTitleText = styled(Typography)(({ theme }) => ({
  color: theme.palette.background.paper,
  textAlign: "left",
  fontSize: "1.7rem",
  lineHeight: "80px",
  height: "35vh",

  backgroundColor: "transparent",
  fontWeight: 800,
  [theme.breakpoints.up("lg")]: {
    fontSize: "94px",
    lineHeight: "100px",
  },
  [theme.breakpoints.between("md", "lg")]: {
    fontSize: "72px",
    lineHeight: "72px",
    height: "210px",
  },
  [theme.breakpoints.between("sm", "md")]: {
    fontSize: "48px",
    lineHeight: "50px",
    height: "160px",
  },

  [theme.breakpoints.down("sm")]: {
    textAlign: "center",
    fontSize: "36px",
    lineHeight: "42px",
    height: "130px",
  },
}));

const HeaderDesc = styled(Typography)(({ theme }) => ({
  fontFamily: "Oswald",
  ...theme.typography.h6,
  fontWeight: 800,

  [theme.breakpoints.down("md")]: {
    ...theme.typography.subtitle2,
    padding: "40px",
    boxSizing: "border-box",
  },
}));
const HeaderContainer = styled(Stack)(({ theme }) => ({
  width: "100%",
  height: "100vh",
  justifyContent: "center",
  alignContent: "flex-start",
  backgroundImage: `url(media/home/hand.jpg)`,
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  //backgroundSize: "contain",
  backgroundPosition: "top",
  padding: "0px 60px",

  [theme.breakpoints.down("md")]: {
    justifyContent: "center",
    height: "100vh",
    padding: "24px ",
  },
}));

const HomeHeader = () => {
  const { theme } = useTheme();
  const { t } = useTranslation();

  return (
    <HeaderContainer id="homeRef">
      <Stack
        sx={{
          gap: "24px",
          alignContent: "center",
          color: theme.palette.background.paper,
          maxWidth: { sm: "100%", md: "50%" },
        }}
      >
        <HeaderDesc>
          {t("home.home1")}

          <br />
          {t("home.home2")}
        </HeaderDesc>
        <HeaderTitleText>
          <Typewriter
            options={{
              strings: [t("home.home3")],
              /*         strings: [" YENİ BİR VİZYON YARATIYORUZ"], */
              autoStart: true,
              loop: true,
              deleteSpeed: 80,
            }}
          />
        </HeaderTitleText>
      </Stack>
    </HeaderContainer>
  );
};

export default HomeHeader;

/* 
const HomeHeader = () => {
  const animation = useSpring({
    to: { opacity: 1, transform: "translate3d(0, 0, 0)" },
    from: { opacity: 0, transform: "translate3d(0, 200px, 0)" },
  });
  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      padding={"32px"}
      height={"95vh"}
      spacing={2}
      sx={{ backgroundColor: "transparent" }}
    >
      <Grid sx={{ backgroundColor: "transparent" }} item xs={12} sm={6}>
        <HeaderTitleText>
          <Typewriter
            options={{
              strings: [" YENİ BİR VİZYON YARATIYORUZ"],
              autoStart: true,
              loop: true,
              deleteSpeed: 70,
            }}
          />
        </HeaderTitleText>
      </Grid>
      <Grid sx={{ backgroundColor: "transparent" }} item xs={9} sm={5}>
        <animated.div style={animation}>
          <Stack width={"100%"} alignItems={"center"} justifyContent={"center"}>
            <img
              style={imgStyle}
              src="media/home/firstHomeImg.svg"
              data-base-url="media/home/firstHomeImg"
              data-extension=".svg"
              alt=""
            />
          </Stack>
        </animated.div>
      </Grid>
    </Grid>
  );
};

export default HomeHeader;
 */
