import React from "react";
import { Stack, Typography, styled } from "@mui/material";
import { useTranslation } from "react-i18next";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { useTheme } from "layouts/theme/ThemeContext";
import { AnimationOnScroll } from "react-animation-on-scroll";
const HeaderContainer = styled(Stack)(({ theme }) => ({
  width: "100%",
  height: "100vh",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  gap: "8px",
  padding: "0px 60px",
  [theme.breakpoints.down("md")]: {
    justifyContent: "center",
    height: "100%",
    flexDirection: "column-reverse",
    padding: "60px 24px",
  },
}));

const ListText = styled(Typography)(({ theme }) => ({
  ...theme.typography.body1,
  fontWeight: 700,
  [theme.breakpoints.down("md")]: {
    ...theme.typography.subtitle2,
    fontWeight: 700,
  },
}));

const arrRPA = [
  "rpa.whyRPADesc1",
  "rpa.whyRPADesc2",
  "rpa.whyRPADesc3",
  "rpa.whyRPADesc5",
  "rpa.whyRPADesc6",
  "rpa.whyRPADesc7",
  "rpa.whyRPADesc8",
  "rpa.whyRPADesc9",
  "rpa.whyRPADesc10",
  "rpa.whyRPADesc11",
  "rpa.whyRPADesc12",
  "rpa.whyRPADesc13",
  "rpa.whyRPADesc14",
];

const WhatisRPA = () => {
  const { theme } = useTheme();
  const { t } = useTranslation();

  return (
    <HeaderContainer>
      <Stack flex={4}>
        {arrRPA.map((item, i) => {
          return (
            <AnimationOnScroll
              animateIn="animate__fadeInLeft"
              duration={0.65 * (i / 2)}
              style={{ maxWidth: "100%" }}
              key={`arrRPA_${i}`}
            >
              <Stack
                direction={"row"}
                sx={{
                  alignItems: "flex-start",
                  gap: "8px",
                }}
              >
                <FiberManualRecordIcon
                  sx={{
                    marginTop: "5px",
                    color: theme.palette.primary.main,
                    fontSize: "14px",
                  }}
                />
                <ListText dangerouslySetInnerHTML={{ __html: t(item) }} />
              </Stack>
            </AnimationOnScroll>
          );
        })}
      </Stack>
      <Stack flex={5}>
        <AnimationOnScroll animateIn="animate__fadeInRight" duration={0.85}>
          <img
            width={"100%"}
            src={"media/rpa/whatIsRpa.svg"}
            data-base-url={"media/rpa/whatIsRpa"}
            data-extension=".svg"
            alt=""
          />
        </AnimationOnScroll>
      </Stack>
    </HeaderContainer>
  );
};

export default WhatisRPA;
