import React from "react";

import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Stack, alpha, styled } from "@mui/material";
import { useTheme } from "layouts/theme/ThemeContext";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";

export const LinkButton = styled(Button)(({ theme }) => ({
  ...theme.typography.body2,
  textTransform: "lowercase",
  fontWeight: 700,
  backgroundColor: "transparent",
  gap: "8px",
  color: theme.palette.background.paper,
  "&:hover": {
    color: theme.palette.warning.dark,
    transition: "all 0.2s ease-in-out",
    transform: " scale(1.1)",
  },
}));

export const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  height: "48px",
  color: theme.palette.background.paper,

  "&:hover": {
    color: theme.palette.warning.dark,
    transition: "all 0.2s ease-in-out",
    transform: " scale(1.1)",
  },
}));

export const StyledMenuMobil = styled(MenuItem)(({ theme }) => ({
  height: "30px",
  ...theme.typography.caption,
  color: theme.palette.background.paper,
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  width: "1**%",
  "&:hover": {
    color: theme.palette.warning.dark,
    transition: "all 0.2s ease-in-out",
    transform: " scale(1.1)",
  },
}));

export default function BasicMenu({
  title,
  subTitles,
}: {
  title: string;
  subTitles: string[];
}) {
  const { theme } = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isShow, setShow] = React.useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const scrollToRef = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div>
      <Stack
        sx={{
          display: { xs: "none", md: "flex" },
        }}
      >
        <LinkButton
          id="basic-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={(e) => {
            handleClick(e);
          }}
        >
          {title}
        </LinkButton>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: "22px",
              borderRadius: "0px 0px 16px 16px",

              "& .MuiList-root": {
                padding: "24px",
                borderRadius: "0px 0px 16px 16px",
                background:
                  " linear-gradient(45deg, rgba(10,9,15,1) 0%, rgba(3,34,50,1) 30%, rgba(6,47,70,1) 68%, rgba(10,9,15,1) 100%)",
              },
            },
          }}
        >
          {subTitles.map((item, i) => {
            return (
              <StyledMenuItem
                key={`subTitles${i}`}
                onClick={(e) => {
                  navigate(`/${title}`);
                  setTimeout(() => {
                    scrollToRef(item);
                  }, 50);

                  handleClose();
                }}
              >
                {t(item)}
              </StyledMenuItem>
            );
          })}
        </Menu>
      </Stack>
      <Stack
        sx={{
          display: { xs: "flex", md: "none" },
        }}
      >
        <LinkButton
          onClick={(e) => {
            setShow(!isShow);
          }}
        >
          {" "}
          <Stack>{isShow === false ? <AddIcon /> : <RemoveIcon />}</Stack>
          {title}
        </LinkButton>
        {
          <Stack
            sx={{
              maxWidth: "170px",
              borderRadius: "8px",
              boxShadow: ` ${alpha(
                theme.palette.background.paper,
                0.26
              )}  0px 3px 6px,${alpha(
                theme.palette.background.default,
                0.23
              )}  0px 3px 6px `,
            }}

            /* box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px; */
          >
            {isShow === true &&
              subTitles.map((item, i) => {
                return (
                  <StyledMenuMobil
                    key={`subTitles${i}`}
                    onClick={(e) => {
                      navigate(`/${title}`);
                      setTimeout(() => {
                        scrollToRef(item);
                      }, 50);

                      handleClose();
                    }}
                  >
                    {t(item)}
                  </StyledMenuMobil>
                );
              })}
          </Stack>
        }
      </Stack>
    </div>
  );
}
