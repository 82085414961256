export const projectInfoArr = [
  {
    name: "Merkezi Alarm Yönetimi",
    text: "Tüm entegre edilen alarmlarınızı tek bir merkezden görüntüleyebileceğiniz Alarm Paneli. Ayrıca ürünümüzde alarmınızın çözümünü ve oluşturulacak Ansible sorun giderme scriptini Tek tuş ile OpenAI (ChatGPT) üzerinden oluşturup, yönetici onayına sunabilirsiniz.",
    img: "media/home/merkeziAlarmYonetimi.png",
    imgLink: "media/home/merkeziAlarmYonetimi",
    status: true,
  },
  {
    name: "Makine öğrenmesiyle ayrıntılı analiz ve raporlama",
    text: "Entegre edilen ürünleriniz üzerinden Dashboard ve Raporlar oluşturabilirsiniz. Bu raporları istediğiniz zaman aralıklarında müşterilerinize otomatik gönderim sağlayabilirsiniz. <br /> İstenilirse Custom Dashboard`ları otomasyonlar aracılığı ile oluşturabilirsiniz.",
    img: "media/home/analiz.png",
    imgLink: "media/home/analiz",
    status: false,
  },
  {
    name: "Kolay Entegrasyon",
    text: "CRM yazılımlarından rapor çekebilmek için hızlı entegrasyon servisleri <br /> Farklı monitor yazılımlarından alarm çekebilmek için hızlı entegrasyon servisleri <br /> Alarmları kaydetmek ve rapor çekebilmek için ITSM hızlı entegrasyon servisleri",
    img: "media/home/kolayEntegrasyon.png",
    imgLink: "media/project/kolayEntegrasyon",
    status: true,
  },
  {
    name: "Yapay zeka destekli Ansible ve RPA Robotlar ile hızlı aksiyon ve sorun giderme",
    text: "Sunucu alarmlarınızı Ansible scriptlerde eşleştirip sorunu giderebilir. <br /> Sorun giderme harici yapılan Robotik süreçlerinizi RPA robotları ile tasarlayıp, yüksek maliyetlerdeki robotları NoCode/LowCode teknolojisi ile sürükle bırak yöntemiyle tasarlayabilirsiniz.",
    img: "media/home/ansible.png",
    imgLink: "media/home/ansible",
    status: false,
  },
  {
    name: "Kolay Kullanım",
    text: "Hangi üründen geldiği farketmeksizin alarmlarınızın tamamını isteklerinize göre filtreler oluşturabilir, Bu filtreler için escalasyon matrisine göre farklı kişilere Mail, Slack, Otomatik santral araması ve alarmı santral ürününe otomatik okutma gibi işlemleri yapabilirsiniz. ITSM ürününüze o müşteri için otomatik ticket oluşturabilir, alarm kapandığında da ticket`i kapatabilirsiniz. ITSM raporlarından oluşturulan ticket ve aramaların raporlarını Robenice ürünü üzerinden müşterilerinize gönderebilirsiniz.",
    img: "media/home/kolayKullanım.png",
    imgLink: "media/home/kolayKullanım",
    status: true,
  },
];
