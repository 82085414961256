import { Stack, Typography, styled } from "@mui/material";
import TitleContent from "components/web/TitleContent";
import React from "react";
import ContactForm from "./ContactForm";
import AdressLine from "./AdressLine";
import { useTranslation } from "react-i18next";
import { CustomTitle } from "components/components/CustomTitle";

const Container = styled(Stack)(({ theme }) => ({
  width: "100%",
  minHeight: "80vh",
  alignItems: "center",
  justifyContent: "center",
  gap: "42px",
  padding: "24px",
  [theme.breakpoints.only("xs")]: {
    gap: "10px",
  },
}));

const BodyContainer = styled(Stack)(({ theme }) => ({
  width: "100%",
  gap: "48px",
  display: "flex",
  maxWidth: "1920px",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  boxSizing: "border-box",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    padding: "60px",
  },
  [theme.breakpoints.only("xs")]: {
    flexDirection: "column",
    padding: "24px",
  },
}));

const SubTitle = styled(Stack)(({ theme }) => ({
  ...theme.typography.body1,
  textAlign: "center",
  padding: "0px 20px",
  maxWidth: "450px",
  color: theme.palette.primary.dark,
  [theme.breakpoints.down("sm")]: {
    ...theme.typography.body2,
    textAlign: "center",
    color: theme.palette.primary.main,
  },
}));

const ContactView = () => {
  const { t } = useTranslation();
  return (
    <Container>
      <Stack alignItems={"center"}>
        <CustomTitle
          shadowTitle={t("contactUs.contactB")}
          title={t("contactUs.contact")}
        />
        <SubTitle>{t("contactUs.contactDesc")}</SubTitle>
      </Stack>
      <BodyContainer>
        <ContactForm />
        <AdressLine />
      </BodyContainer>
    </Container>
  );
};

export default ContactView;
