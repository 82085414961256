import React from "react";
import { styled, Stack, Typography } from "@mui/material";
import { useSpring } from "@react-spring/web";
import Typewriter from "typewriter-effect";
import { useTheme } from "layouts/theme/ThemeContext";
import { useTranslation } from "react-i18next";
import WhatisRPA from "./components/WhatisRPA";
import RpaApplicationAreas from "./components/RpaApplicationAreas";
import RpaUsageAreas from "./components/RpaUsageAreas";
import RpaUsageAreasType from "./components/RpaUsageAreasType";
import WhyRobeniceRpa from "./components/WhyRobeniceRpa";

const HeaderTitleText = styled(Typography)(({ theme }) => ({
  color: theme.palette.background.paper,
  textAlign: "left",
  backgroundColor: "transparent",
  fontWeight: 800,
  [theme.breakpoints.up("lg")]: {
    fontSize: "96px",
    lineHeight: "60px",
  },
  [theme.breakpoints.between("md", "lg")]: {
    fontSize: "56px",
    lineHeight: "60px",
  },
  [theme.breakpoints.between("sm", "md")]: {
    fontSize: "42px",
    lineHeight: "42px",
  },

  [theme.breakpoints.down("sm")]: {
    textAlign: "center",
    fontSize: "36px",
    lineHeight: "42px",
  },
}));

const HeaderContainer = styled(Stack)(({ theme }) => ({
  width: "100%",
  height: "100vh",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  backgroundImage: `url(media/rpa/header.jpg)`,
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "bottom",
  color: theme.palette.background.paper,
  padding: "0px 60px",

  [theme.breakpoints.down("md")]: {
    justifyContent: "center",
    height: "100vh",
    padding: "24px ",
  },
}));

const RpaView = () => {
  const { theme } = useTheme();
  const { t } = useTranslation();

  return (
    <Stack>
      <Stack id="topbar.whatisRPA" />
      <HeaderContainer>
        <HeaderTitleText>
          <Typewriter
            options={{
              strings: [t("rpa.whyRPA")],
              autoStart: true,
              loop: true,
              deleteSpeed: 80,
            }}
          />
        </HeaderTitleText>
      </HeaderContainer>
      <WhatisRPA />
      <Stack id="topbar.rpaApplicationAreas" />
      <RpaApplicationAreas />
      <Stack id="topbar.areasOfUsage" />
      <RpaUsageAreas />
      {rpaUsageAreasArr.map((item, i) => {
        return (
          <RpaUsageAreasType
            key={`rpaUsageAreasArr${i}`}
            title={t(item.title)}
            rotate={item.rotate}
            textlist={t(item.textlist)}
            img={item.img}
            imgShort={item.imgShort}
            textTitle1={t(item.textTitle1)}
            textDesc1={t(item.textDesc1)}
            textTitle2={t(item.textTitle2)}
            textDesc2={t(item.textDesc2)}
          />
        );
      })}
      <Stack id="topbar.whyRobeniceRPA" />
      <WhyRobeniceRpa />
    </Stack>
  );
};

export default RpaView;

const rpaUsageAreasArr = [
  {
    title: "rpa.rpaHealth",
    rotate: true,
    textlist: "rpa.rpaHealthDesc",
    textTitle1: "",
    textDesc1: "",
    textTitle2: "",
    textDesc2: "",
    img: "media/rpa/rpaHealth.svg",
    imgShort: "media/rpa/rpaHealth",
  },
  {
    title: "rpa.rpaLogistics",
    rotate: false,
    textlist: "",
    textTitle1: "rpa.rpaLogisticsText1",
    textDesc1: "rpa.rpaLogisticsText2",
    textTitle2: "rpa.rpaLogisticsText3",
    textDesc2: "rpa.rpaLogisticsText4",
    img: "media/rpa/rpaLogistics.svg",
    imgShort: "media/rpa/rpaLogistics",
  },
  {
    title: "rpa.rpaHR",
    rotate: true,
    textlist: "",
    textTitle1: "rpa.rpaHRText1",
    textDesc1: "rpa.rpaHRText2",
    textTitle2: "rpa.rpaHRText3",
    textDesc2: "rpa.rpaHRText4",
    img: "media/rpa/rpaHR.svg",
    imgShort: "media/rpa/rpaHR",
  },
  {
    title: "rpa.rpaProduction",
    rotate: false,
    textlist: "",
    textTitle1: "rpa.rpaProductionText1",
    textDesc1: "rpa.rpaProductionText2",
    textTitle2: "",
    textDesc2: "",
    img: "media/rpa/rpaProduction.svg",
    imgShort: "media/rpa/rpaProduction",
  },
];
