import React from "react";
import { styled, Stack, Typography } from "@mui/material";
import { useSpring } from "@react-spring/web";
import Typewriter from "typewriter-effect";
import { useTheme } from "layouts/theme/ThemeContext";

const HeaderTitleText = styled(Typography)(({ theme }) => ({
  color: theme.palette.background.paper,
  textAlign: "left",

  backgroundColor: "transparent",
  fontWeight: 800,
  [theme.breakpoints.up("lg")]: {
    fontSize: "56px",
    lineHeight: "60px",
    height: "180px",
  },
  [theme.breakpoints.between("md", "lg")]: {
    fontSize: "56px",
    lineHeight: "60px",
    height: "180px",
  },
  [theme.breakpoints.between("sm", "md")]: {
    fontSize: "42px",
    lineHeight: "42px",
    height: "160px",
  },

  [theme.breakpoints.down("sm")]: {
    textAlign: "center",
    fontSize: "36px",
    lineHeight: "42px",
    height: "130px",
  },
}));

const HeaderContainer = styled(Stack)(({ theme }) => ({
  width: "100%",
  height: "100vh",
  justifyContent: "center",
  alignContent: "flex-start",
  backgroundImage: `url(media/home/continuing.png)`,
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  //backgroundSize: "contain",
  backgroundPosition: "top",
  padding: "0px 60px",

  [theme.breakpoints.down("md")]: {
    justifyContent: "center",
    height: "100vh",
    padding: "24px ",
  },
}));

const OrchestratorPage = () => {
  const { theme } = useTheme();

  return (
    <HeaderContainer>
      <Stack
        sx={{
          gap: "24px",
          alignContent: "center",
          color: theme.palette.background.paper,
          maxWidth: { sm: "100%", md: "50%" },
        }}
      >
        <HeaderTitleText>
          <Typewriter
            options={{
              strings: [
                "web sitemiz için hazırlıklar devam ediyor, bizi takipte kalın!",
              ],
              /*         strings: [" YENİ BİR VİZYON YARATIYORUZ"], */
              autoStart: true,
              loop: true,
              deleteSpeed: 80,
            }}
          />
        </HeaderTitleText>
      </Stack>
    </HeaderContainer>
  );
};

export default OrchestratorPage;
