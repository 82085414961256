import { CustomStyleButton } from "components/buttons/CustomButton";
import React, { useState } from "react";
import { Stack, TextField, Typography, styled } from "@mui/material";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { Form, Formik, FormikProps } from "formik";

const SubContainer = styled(Stack)(({ theme }) => ({
  width: "100%",
  gap: "12px",
  maxWidth: "550px",

  [theme.breakpoints.only("xs")]: {
    padding: "12px",
    maxWidth: "300px",
  },
}));
const Container = styled(Stack)(({ theme }) => ({
  width: "100%",
  gap: "12px",
  flexDirection: "row",
  [theme.breakpoints.only("xs")]: {
    flexDirection: "column",
  },
}));

interface IContactForm {
  firstName: string;
  lastName: string;
  email: string;
  company: string;
  message: string;
}

const ContactForm = () => {
  const { t } = useTranslation();

  const ContactFormSchema = Yup.object().shape({
    firstName: Yup.string()
      .required("Required field")
      .min(2, "First Name too short"),
    lastName: Yup.string()
      .required("Required field")
      .min(2, "Last Name too short"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Required field"),
    company: Yup.string()
      .required("Required field")
      .min(2, "Company too short"),
    message: Yup.string()
      .required("Required field")
      .min(2, "Message too short"),
  });

  return (
    <Formik
      initialValues={{
        firstName: "",
        lastName: "",
        email: "",
        company: "",
        message: "",
      }}
      validationSchema={ContactFormSchema}
      onSubmit={(values: IContactForm, actions) => {}}
    >
      {(props: FormikProps<IContactForm>) => {
        const {
          values,
          touched,
          errors,
          dirty,
          handleBlur,
          handleChange,
          handleSubmit,
          setSubmitting,
        } = props;
        return (
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
              setSubmitting(false);
            }}
          >
            <SubContainer>
              <Container>
                <Stack width={"100%"}>
                  <Typography variant="subtitle2">
                    {t("contactUs.firstName")}
                  </Typography>
                  <TextField
                    id="form_firstName"
                    name="firstName"
                    placeholder="Enter..."
                    fullWidth
                    variant="outlined"
                    size="small"
                    value={values.firstName}
                    helperText={
                      errors.firstName && touched.firstName
                        ? errors.firstName
                        : " "
                    }
                    error={errors.firstName && touched.firstName ? true : false}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Stack>
                <Stack width={"100%"}>
                  <Typography variant="subtitle2">
                    {t("contactUs.lastName")}
                  </Typography>
                  <TextField
                    id="form_lastName"
                    name="lastName"
                    placeholder="Enter..."
                    fullWidth
                    variant="outlined"
                    size="small"
                    value={values.lastName}
                    helperText={
                      errors.lastName && touched.lastName
                        ? errors.lastName
                        : " "
                    }
                    error={errors.lastName && touched.lastName ? true : false}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Stack>
              </Container>
              <Container>
                <Stack width={"100%"}>
                  <Typography variant="subtitle2">
                    {t("contactUs.email")}
                  </Typography>
                  <TextField
                    id="form_email"
                    name="email"
                    placeholder="Enter..."
                    fullWidth
                    variant="outlined"
                    size="small"
                    value={values.email}
                    helperText={
                      errors.email && touched.email ? errors.email : " "
                    }
                    error={errors.email && touched.email ? true : false}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Stack>
                <Stack width={"100%"}>
                  <Typography variant="subtitle2">
                    {t("contactUs.company")}
                  </Typography>
                  <TextField
                    id="form_company"
                    name="company"
                    placeholder="Enter..."
                    fullWidth
                    variant="outlined"
                    size="small"
                    value={values.company}
                    helperText={
                      errors.company && touched.company ? errors.company : " "
                    }
                    error={errors.company && touched.company ? true : false}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Stack>
              </Container>
              <Container>
                <Stack width={"100%"}>
                  <Typography variant="subtitle2">
                    {t("contactUs.message")}{" "}
                  </Typography>
                  <TextField
                    id="form_message"
                    multiline
                    name="message"
                    rows={4}
                    fullWidth
                    variant="outlined"
                    size="small"
                    value={values.message}
                    helperText={
                      errors.message && touched.message ? errors.message : " "
                    }
                    error={errors.message && touched.message ? true : false}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Stack>
              </Container>
              <CustomStyleButton>Send Message</CustomStyleButton>
            </SubContainer>
          </Form>
        );
      }}
    </Formik>
  );
};

export default ContactForm;
