import React from "react";
import { Stack, Typography, styled } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useTheme } from "layouts/theme/ThemeContext";
import { AnimationOnScroll } from "react-animation-on-scroll";
import { CustomTitle } from "components/components/CustomTitle";

const HeaderContainer = styled(Stack)(({ theme }) => ({
  width: "100%",
  height: "100vh",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  gap: "8px",
  padding: "0px 60px",
  [theme.breakpoints.down("md")]: {
    justifyContent: "center",
    height: "100%",
    gap: "16px",
    flexDirection: "column",
    padding: "60px 24px",
  },
}));

const Text = styled(Typography)(({ theme }) => ({
  ...theme.typography.body1,
  fontWeight: 700,
  [theme.breakpoints.down("md")]: {
    ...theme.typography.subtitle2,
    fontWeight: 700,
  },
}));
const ListText = styled(Typography)(({ theme }) => ({
  ...theme.typography.body2,
  fontWeight: 700,
  [theme.breakpoints.down("md")]: {
    ...theme.typography.caption,
    fontWeight: 700,
  },
}));

const arrRPA = [
  "rpa.whyRPADesc1",
  "rpa.whyRPADesc2",
  "rpa.whyRPADesc3",
  "rpa.whyRPADesc5",
  "rpa.whyRPADesc6",
  "rpa.whyRPADesc7",
  "rpa.whyRPADesc8",
  "rpa.whyRPADesc9",
  "rpa.whyRPADesc10",
  "rpa.whyRPADesc11",
  "rpa.whyRPADesc12",
  "rpa.whyRPADesc13",
  "rpa.whyRPADesc14",
];

const RpaApplicationAreas = () => {
  const { theme } = useTheme();
  const { t } = useTranslation();

  return (
    <HeaderContainer>
      <Stack flex={5}>
        <AnimationOnScroll animateIn="animate__fadeInLeft" duration={0.85}>
          <img
            width={"100%"}
            src={"media/rpa/rpaApplicationGraphic.svg"}
            data-base-url={"media/rpa/rpaApplicationGraphic"}
            data-extension=".svg"
            alt=""
          />
        </AnimationOnScroll>
      </Stack>
      <Stack
        sx={{
          gap: { xs: "32px", md: "12px" },
        }}
        flex={7}
      >
        <CustomTitle
          shadowTitle={t("rpa.rpaApplicationAreas").toUpperCase()}
          title={t("rpa.rpaApplicationAreas")}
        />
        <Stack
          sx={{
            gap: { xs: "12px", md: "8px" },
          }}
        >
          <AnimationOnScroll
            animateIn="animate__fadeInRight"
            duration={0.65}
            style={{ maxWidth: "100%" }}
          >
            <Text
              dangerouslySetInnerHTML={{ __html: t("rpa.rpaApplicationDesc1") }}
            />
          </AnimationOnScroll>
          <AnimationOnScroll
            animateIn="animate__fadeInRight"
            duration={0.65}
            style={{ maxWidth: "100%" }}
          >
            <ListText
              dangerouslySetInnerHTML={{ __html: t("rpa.rpaApplicationDesc2") }}
            />
          </AnimationOnScroll>
          <AnimationOnScroll
            animateIn="animate__fadeInRight"
            duration={0.65}
            style={{ maxWidth: "100%" }}
          >
            <Text
              dangerouslySetInnerHTML={{ __html: t("rpa.rpaApplicationDesc3") }}
            />
          </AnimationOnScroll>
        </Stack>
      </Stack>
    </HeaderContainer>
  );
};

export default RpaApplicationAreas;
