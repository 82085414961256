import React, { FC, useState, useEffect } from "react";
import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
import App from "../App";
import HomePage from "page/home/HomePage";
import MasterLayout from "layouts/MasterLayout";
import RpaView from "page/rpa/RpaView";
import OrchestratorPage from "page/orchestrator/OrchestratorPage";

const AppRoutes: FC = () => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  return (
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <Routes>
        <Route element={<App />}>
          <Route path="/*" element={<PrivateRoutes />} />
          <Route index element={<Navigate to="/home" />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export { AppRoutes };

const PrivateRoutes = () => {
  return (
    <Routes>
      <Route element={<MasterLayout />}>
        <Route path="home" element={<HomePage />} />
        <Route path="RPA" element={<RpaView />} />
        <Route path="orchestrator" element={<OrchestratorPage />} />
        <Route path="*" element={<Navigate to="/error/404" />} />
      </Route>
    </Routes>
  );
};
