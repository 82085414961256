// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `a {
  /*   font-family: "Marck Script"; */
  font-size: 14px;
  letter-spacing: 0.5px;
  padding: 0px 10px;
  color: #2c3e50;
  text-decoration: none;
}
a:hover {
  color: #de481e;
  font-weight: bold;
  transition: all 0.2s ease-in-out;
  transform: scale(1.1);
}

.exactactive {
  color: #de481e;
  text-decoration: none;
  font-weight: bold;
}
`, "",{"version":3,"sources":["webpack://./src/layouts/topbar/Topbar_styles.css"],"names":[],"mappings":"AAAA;EACE,mCAAmC;EACnC,eAAe;EACf,qBAAqB;EACrB,iBAAiB;EACjB,cAAc;EACd,qBAAqB;AACvB;AACA;EACE,cAAc;EACd,iBAAiB;EACjB,gCAAgC;EAChC,qBAAqB;AACvB;;AAEA;EACE,cAAc;EACd,qBAAqB;EACrB,iBAAiB;AACnB","sourcesContent":["a {\n  /*   font-family: \"Marck Script\"; */\n  font-size: 14px;\n  letter-spacing: 0.5px;\n  padding: 0px 10px;\n  color: #2c3e50;\n  text-decoration: none;\n}\na:hover {\n  color: #de481e;\n  font-weight: bold;\n  transition: all 0.2s ease-in-out;\n  transform: scale(1.1);\n}\n\n.exactactive {\n  color: #de481e;\n  text-decoration: none;\n  font-weight: bold;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
