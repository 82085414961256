import React from "react";
import { Stack, Typography, styled } from "@mui/material";
import { useTranslation } from "react-i18next";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { useTheme } from "layouts/theme/ThemeContext";
import { AnimationOnScroll } from "react-animation-on-scroll";
import { CustomTitleShort } from "components/components/CustomTitle";
const HeaderContainer = styled(Stack)(({ theme }) => ({
  width: "100%",
  height: "70vh",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  gap: "8px",
  padding: "0px 60px",
  [theme.breakpoints.down("md")]: {
    justifyContent: "center",
    height: "100%",
    gap: "16px",
    flexDirection: "column",
    padding: "60px 24px",
  },
}));

const arrRPA = [
  "rpa.rpaUsageAreasDesc1",
  "rpa.rpaUsageAreasDesc2",
  "rpa.rpaUsageAreasDesc3",
  "rpa.rpaUsageAreasDesc4",
  "rpa.rpaUsageAreasDesc5",
  "rpa.rpaUsageAreasDesc6",
  "rpa.rpaUsageAreasDesc7",
  "rpa.rpaUsageAreasDesc8",
];

const TitleShort = styled(Typography)(({ theme }) => ({
  ...theme.typography.h4,
  fontWeight: 700,
  [theme.breakpoints.down("md")]: {
    ...theme.typography.h5,
    textAlign: "center",
    fontWeight: 700,
  },
}));

const RpaUsageAreasType = ({
  title,
  rotate,
  textlist,
  img,
  imgShort,
  textTitle1,
  textDesc1,
  textTitle2,
  textDesc2,
}: {
  title: string;
  rotate: boolean;
  textlist: string;
  img: string;
  imgShort: string;
  textTitle1: string;
  textDesc1: string;
  textTitle2: string;
  textDesc2: string;
}) => {
  const { theme } = useTheme();
  const { t } = useTranslation();

  return (
    <HeaderContainer
      sx={{
        flexDirection: {
          xs: "column-reverse",
          md: rotate === true ? "row" : "row-reverse",
        },
      }}
    >
      {" "}
      {textlist === "" ? (
        <Stack gap={"24px"} flex={5}>
          <AnimationOnScroll
            animateIn={
              rotate === false ? "animate__fadeInRight" : "animate__fadeInLeft"
            }
            duration={0.65}
            style={{ maxWidth: "100%" }}
          >
            <TitleShort> {title}</TitleShort>
          </AnimationOnScroll>

          <Stack gap={"12px"}>
            <AnimationOnScroll
              animateIn={
                rotate === false
                  ? "animate__fadeInRight"
                  : "animate__fadeInLeft"
              }
              duration={0.65}
              style={{ maxWidth: "100%" }}
            >
              <Typography
                variant="body1"
                sx={{
                  fontWeight: 700,
                }}
                dangerouslySetInnerHTML={{ __html: textTitle1 }}
              />
            </AnimationOnScroll>
            <AnimationOnScroll
              animateIn={
                rotate === false
                  ? "animate__fadeInRight"
                  : "animate__fadeInLeft"
              }
              duration={0.65}
              style={{ maxWidth: "100%" }}
            >
              <Typography
                variant="body1"
                dangerouslySetInnerHTML={{ __html: textDesc1 }}
              />
            </AnimationOnScroll>
          </Stack>

          {textTitle2 !== "" && (
            <Stack gap={"12px"}>
              <AnimationOnScroll
                animateIn={
                  rotate === false
                    ? "animate__fadeInRight"
                    : "animate__fadeInLeft"
                }
                duration={0.65}
                style={{ maxWidth: "100%" }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    fontWeight: 700,
                  }}
                  dangerouslySetInnerHTML={{ __html: textTitle2 }}
                />
              </AnimationOnScroll>
              <AnimationOnScroll
                animateIn={
                  rotate === false
                    ? "animate__fadeInRight"
                    : "animate__fadeInLeft"
                }
                duration={0.65}
                style={{ maxWidth: "100%" }}
              >
                <Typography
                  variant="body1"
                  dangerouslySetInnerHTML={{ __html: textDesc2 }}
                />
              </AnimationOnScroll>
            </Stack>
          )}
        </Stack>
      ) : (
        <Stack flex={5}>
          <TitleShort> {title}</TitleShort>
          <AnimationOnScroll
            animateIn={
              rotate === false ? "animate__fadeInRight" : "animate__fadeInLeft"
            }
            duration={0.65}
            style={{ maxWidth: "100%" }}
          >
            <Typography
              variant="body1"
              dangerouslySetInnerHTML={{ __html: textlist }}
            />
          </AnimationOnScroll>
        </Stack>
      )}
      <Stack flex={5}>
        <AnimationOnScroll
          animateIn={
            rotate === true ? "animate__fadeInRight" : "animate__fadeInLeft"
          }
          duration={0.85}
        >
          <img
            width={"100%"}
            src={img}
            data-base-url={imgShort}
            data-extension=".svg"
            alt=""
          />
        </AnimationOnScroll>
      </Stack>
    </HeaderContainer>
  );
};

export default RpaUsageAreasType;
