import React from "react";
import AppContainer from "components/box/AppContainer";
import Topbar from "./topbar/Topbar";
import { Outlet } from "react-router";
import Footer from "./footer/Footer";
import ParticlesContainer from "./backgroundAnimation/BackgroundAnimation";

const MasterLayout = () => {
  return (
    <AppContainer>
      <ParticlesContainer />
      <div
        style={{
          width: "100%",
          zIndex: 10,
        }}
      >
        <Topbar />
        <Outlet />
        <Footer />
      </div>
    </AppContainer>
  );
};

export default MasterLayout;
