import React, { useState } from "react";
import { Stack, Button, TextField, Typography, styled } from "@mui/material";
import MailIcon from "@mui/icons-material/Mail";
import MapIcon from "@mui/icons-material/Map";
import PhoneIcon from "@mui/icons-material/Phone";
import { useTheme } from "layouts/theme/ThemeContext";
import { animated, useSpring } from "@react-spring/web";
import { useTranslation } from "react-i18next";

const SubContainer = styled(Stack)(({ theme }) => ({
  width: "100%",
  gap: "48px",
  maxWidth: "550px",
  [theme.breakpoints.down("md")]: {
    /*   padding: "54px", */
  },
}));

const SubButton = styled(Button)<{ select: boolean }>(({ theme, select }) => ({
  textDecoration: "none",
  ...theme.typography.body2,
  textTransform: "none",
  color: select ? theme.palette.primary.dark : theme.palette.secondary.dark,
  fontWeight: select ? 700 : 400,
}));

const ItemContainer = styled(Stack)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  gap: "16px",
}));

const ItemCom = ({
  icon,
  desc,
  desc1,
  link,
}: {
  icon: string;
  desc: string;
  desc1: string;
  link?: string;
}) => {
  const { theme } = useTheme();
  const animation = useSpring({
    to: { opacity: 1, transform: "translate3d(0, 0, 0)" },
    from: { opacity: 0, transform: "translate3d(0, 15px, 0)" },
  });
  return (
    <animated.div style={animation}>
      <ItemContainer>
        <Stack
          sx={{
            width: "40px",
            height: "40px",
            borderRadius: "25px",
            backgroundColor: theme.palette.warning.dark,
            alignItems: "center",
            justifyContent: "center",
            transition: "background-color 0.3s",
          }}
        >
          {"mail" === icon && (
            <MailIcon
              sx={{
                color: theme.palette.background.default,
              }}
            />
          )}
          {"location" === icon && (
            <MapIcon
              sx={{
                color: theme.palette.background.default,
              }}
            />
          )}
          {"phone" === icon && (
            <PhoneIcon
              sx={{
                color: theme.palette.background.default,
              }}
            />
          )}
        </Stack>
        <Stack>
          <Typography variant="subtitle2">{desc}</Typography>
          <Typography
            onClick={() => {
              {
                link && window.open(link, "_blank");
              }
            }}
            sx={{
              cursor: link && "pointer",
              color: theme.palette.warning.dark,
            }}
            variant="body2"
            dangerouslySetInnerHTML={{ __html: desc1 }}
          />
        </Stack>
      </ItemContainer>
    </animated.div>
  );
};

const AdressLine = () => {
  const { t } = useTranslation();
  const [state, setState] = useState<string>("location");
  return (
    <SubContainer>
      <Stack gap={"12px"}>
        <Stack direction={"row"} gap={"12px"}>
          <SubButton
            onClick={() => {
              setState("email");
            }}
            select={"email" === state}
            disableRipple
          >
            {t("contactUs.email")}
          </SubButton>
          <SubButton
            onClick={() => {
              setState("location");
            }}
            select={"location" === state}
            disableRipple
          >
            {t("contactUs.location")}
          </SubButton>
          <SubButton
            onClick={() => {
              setState("phone");
            }}
            select={"phone" === state}
            disableRipple
          >
            {t("contactUs.phone")}
          </SubButton>
        </Stack>
        {"email" === state && (
          <ItemCom
            desc={t("contactUs.emailDesc")}
            desc1={"info@robenice.com.tr"}
            icon={"mail"}
          />
        )}
        {"location" === state && (
          <ItemCom
            desc={t("contactUs.locationDesc")}
            desc1={
              "Ciftlikköy Mah. <b>Mersin Üniversitesi</b> Kampus Alani Küme evler <b>Teknopark</b> No: 35/B-109 Yenisehir/MERSIN"
            }
            icon={"location"}
            link={
              "https://www.google.com/maps/place/Mersin+Teknopark/@36.7884673,34.4827891,14z/data=!4m10!1m2!2m1!1sCiftlikk%C3%B6y+Mah.+Mersin+%C3%9Cniversitesi+Kampus+Alani+K%C3%BCme+evler+Teknopark+No:+35%2FB-109+Yenisehir%2FMERSIN!3m6!1s0x15278ab00562c77b:0x6775fc41b803495e!8m2!3d36.7884673!4d34.5208979!15sCmZDaWZ0bGlra8O2eSBNYWguIE1lcnNpbiDDnG5pdmVyc2l0ZXNpIEthbXB1cyBBbGFuaSBLw7xtZSBldmxlciBUZWtub3BhcmsgTm86IDM1L0ItMTA5IFllbmlzZWhpci9NRVJTSU5aZiJkY2lmdGxpa2vDtnkgbWFoIG1lcnNpbiDDvG5pdmVyc2l0ZXNpIGthbXB1cyBhbGFuaSBrw7xtZSBldmxlciB0ZWtub3Bhcmsgbm8gMzUgYiAxMDkgeWVuaXNlaGlyIG1lcnNpbpIBE3Jlc2VhcmNoX2ZvdW5kYXRpb26aASRDaGREU1VoTk1HOW5TMFZKUTBGblNVTlJNMHBmZWpSblJSQULgAQA!16s%2Fg%2F11b7qbr6c7?entry=ttu"
            }
          />
        )}
        {"phone" === state && (
          <ItemCom
            desc={t("contactUs.phoneDesc")}
            desc1={"+90 532 177 07 91"}
            icon={"phone"}
          />
        )}
      </Stack>
      <img
        src="media/contact/drawing.svg"
        data-base-url="media/contact/drawing"
        data-extension=".svg"
        alt=""
      />
    </SubContainer>
  );
};

export default AdressLine;
