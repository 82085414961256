import React from "react";
import { Grid, Stack, Typography, styled } from "@mui/material";
import {
  IconAnaliz,
  IconAnsible,
  IconBildirim,
  IconEntegrasyon,
  IconIA,
  IconKullanım,
  IconMerkeziAlarm,
  IconSaveMoney,
  IconWorkflow,
} from "components/icons/HomeIcon";
import { AnimationOnScroll } from "react-animation-on-scroll";
import "animate.css/animate.min.css";
import { CustomTitle } from "components/components/CustomTitle";
import { useTranslation } from "react-i18next";

const Container = styled(Stack)(({ theme }) => ({
  width: "100%",
  height: "100vh",
  justifyContent: "center",
  alignItems: "center",
  boxSizing: "border-box",
  padding: "16px",
  gap: "48px",
  [theme.breakpoints.down("md")]: {
    gap: "24px",
    justifyContent: "center",
    height: "100%",

    flexDirection: "column",
    padding: "60px 24px",
  },
}));

const SubContainer = styled(Stack)(({ theme }) => ({
  width: "100%",
  justifyContent: "center",
  alignItems: "center",
  boxSizing: "border-box",
}));

const arr = [
  {
    icon: <IconSaveMoney sx={{ width: "58px", height: "58px" }} />,
    title: "rpa.rpaAdvantagesText1",
  },
  {
    icon: <IconAnaliz sx={{ width: "58px", height: "58px" }} />,
    title: "rpa.rpaAdvantagesText2",
  },
  {
    icon: <IconIA sx={{ width: "58px", height: "58px" }} />,
    title: "rpa.rpaAdvantagesText3",
  },
  {
    icon: <IconEntegrasyon sx={{ width: "58px", height: "58px" }} />,
    title: "rpa.rpaAdvantagesText4",
  },
  {
    icon: <IconKullanım sx={{ width: "58px", height: "58px" }} />,
    title: "rpa.rpaAdvantagesText5",
  },
  {
    icon: <IconWorkflow sx={{ width: "58px", height: "58px" }} />,
    title: "rpa.rpaAdvantagesText6",
  },
];

const ToolsContainer = styled(Stack)(({ theme }) => ({
  flex: "flex",
  gap: "12px",
  padding: "58px 150px",

  boxSizing: "border-box",
  [theme.breakpoints.between("xs", "sm")]: {
    alignItems: "center",
    padding: "24px 48px",
  },
  [theme.breakpoints.down("xs")]: {
    alignItems: "center",
    padding: "24px 32px",
  },
}));

const ArrContainer = styled(Stack)(({ theme }) => ({
  flex: "flex",
  gap: "12px",
  width: "100%",

  boxSizing: "border-box",
  [theme.breakpoints.down("md")]: {
    justifyContent: "center",
    alignItems: "center",
    padding: "8px",
    boxSizing: "border-box",
  },
}));

const SubContainerDesc = styled(Typography)(({ theme }) => ({
  fontWeight: 300,
  ...theme.typography.subtitle2,
  [theme.breakpoints.down("sm")]: {
    textAlign: "center",
  },
}));
const WhyRobeniceRpa = () => {
  const { t } = useTranslation();
  return (
    <Container>
      <SubContainer>
        <CustomTitle
          shadowTitle={t("rpa.rpaAdvantages").toUpperCase()}
          title={t("rpa.rpaAdvantages")}
        />
        <SubContainerDesc>
          Proje Yönetiminde kusursuz bir deneyimi keşfedin
        </SubContainerDesc>
      </SubContainer>

      <Grid container justifyContent="center" alignItems="center">
        <Grid xs={12} md={6}>
          <AnimationOnScroll animateIn="animate__fadeInLeft" duration={0.65}>
            <ToolsContainer>
              <Typography fontWeight={400} variant="body1">
                {t("rpa.rpaBottomText1")}
                <br />
                <br />
                {t("rpa.rpaBottomText2")}
              </Typography>
            </ToolsContainer>
          </AnimationOnScroll>
        </Grid>
        <Grid xs={12} md={6}>
          <AnimationOnScroll animateIn="animate__fadeInRight" duration={0.65}>
            <HomeArr />
          </AnimationOnScroll>
        </Grid>
      </Grid>
    </Container>
  );
};

export default WhyRobeniceRpa;

const HomeArr = () => {
  const { t } = useTranslation();

  return (
    <Grid container justifyContent="center" alignItems="center">
      {arr.map((item, i) => {
        return (
          <Grid key={`HomeDesing${i}`} xs={8} md={6}>
            <ArrContainer>
              <Stack marginTop={"32px"} height={"58px"}>
                {item.icon}
              </Stack>
              <Typography
                fontWeight={700}
                paddingRight={"8px"}
                variant="subtitle1"
                sx={{
                  textAlign: { xs: "center", md: "left" },
                }}
              >
                {t(item.title)}
              </Typography>
            </ArrContainer>
          </Grid>
        );
      })}
    </Grid>
  );
};
