import { Stack, Typography, alpha, styled } from "@mui/material";
import {
  IconDiamondCore,
  IconRocketCore,
  IconTimeCore,
} from "components/icons/icons";
import { useTheme } from "layouts/theme/ThemeContext";
import React from "react";
import { AnimationOnScroll } from "react-animation-on-scroll";
import "animate.css/animate.min.css";
import { CustomTitle } from "components/components/CustomTitle";

const BodyContainer = styled(Stack)(({ theme }) => ({
  width: "100%",
  display: "flex",
  maxWidth: "1920px",
  flexDirection: "row",
  gap: "32px",
  justifyContent: "center",
  alignItems: "center",
  boxSizing: "border-box",
  padding: "40px 120px",

  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    padding: "60px",
  },
}));
const ItemContainer = styled(Stack)(({ theme }) => ({
  gap: "32px",
  padding: "32px",
  borderRadius: "16px",
  alignItems: "center",
  justifyContent: "center",
  minHeight: "360px",
  backgroundColor: theme.palette.warning.light,
  boxShadow: ` ${alpha(
    theme.palette.secondary.main,
    0.3
  )}  0px 2px 3px 1px,${alpha(
    theme.palette.primary.contrastText,
    0.4
  )}  0px 1px 3px -1px `,

  /*     [theme.breakpoints.down("sm")]: {
        padding: "16px",
      }, */
}));
const IconContainer = styled(Stack)(({ theme }) => ({
  width: "80px",
  height: "80px",
  borderRadius: "40px",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: theme.palette.background.default,
}));

const SubTitle = styled(Stack)(({ theme }) => ({
  ...theme.typography.body1,
  textAlign: "center",
  padding: "0px 20px",
  maxWidth: "450px",
  color: theme.palette.primary.dark,
  [theme.breakpoints.down("sm")]: {
    ...theme.typography.body2,
    textAlign: "center",
    color: theme.palette.primary.main,
  },
}));

const arrData = [
  {
    icons: <IconTimeCore sx={{ width: "56px", height: "56px" }} />,
    title: "Uzmanlık",
    desc: "Biz, sağlam bir uzmanlıkla sektöre yön veriyoruz. Her bir ekibimizdeki üye, kendi uzmanlık alanında en son gelişmeleri takip ederek, müşterilerimize en iyi hizmeti sunma konusundaki taahhüdünü sürdürmektedir.",
  },
  {
    icons: <IconDiamondCore sx={{ width: "56px", height: "56px" }} />,
    title: "Katma Değer",
    desc: "Müşterilerimiz için sadece çözümler üretmekle kalmıyor, aynı zamanda onlara katma değer sağlayan stratejiler geliştiriyoruz. İşbirliği yaptığımız her projede, müşterilerimizin iş süreçlerini iyileştirmeye yönelik yenilikçi yaklaşımlar sunarak, iş sonuçlarını artırmaya odaklanıyoruz.",
  },
  {
    icons: <IconRocketCore sx={{ width: "56px", height: "56px" }} />,
    title: "Sürdürülebilir Büyüme",
    desc: "Sürdürülebilir bir büyüme hedefiyle hareket ediyoruz. Müşterilerimizle uzun vadeli ilişkiler kurarak, iş süreçlerindeki potansiyeli en üst düzeye çıkarıyor ve onların başarılarını desteklemeye odaklanıyoruz. Sürdürülebilir büyüme, bizim için hem müşterilerimizin hem de ekibimizin başarısının anahtarıdır.",
  },
];

const Container = styled(Stack)(({ theme }) => ({
  width: "100%",
  minHeight: "85vh",
  alignItems: "center",
  justifyContent: "center",
  backgroundImage: `url(media/home/futuristicRobotHand.jpg)`,
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "top",
  [theme.breakpoints.down("md")]: {
    padding: "120px 0px",
  },
}));

const BenefitsSection = () => {
  const { theme } = useTheme();
  return (
    <Container>
      <Stack
        sx={{
          color: theme.palette.background.paper,
        }}
        alignItems={"center"}
      >
        <CustomTitle shadowTitle={"UZMAN EKİP"} title={"Uzman Ekip"} />
      </Stack>
      <SubTitle sx={{ color: theme.palette.background.paper }}>
        Ekibimiz, sektörde tanınmış ve deneyimli iş süreci uzmanlarından
        oluşmaktadır.
      </SubTitle>

      <BodyContainer>
        {arrData.map((item, i) => {
          return (
            <Stack key={`benefitsSection${i}`} flex={1}>
              <AnimationOnScroll
                animateIn="animate__fadeInUp"
                duration={0.65 * i}
              >
                <ItemContainer>
                  <IconContainer>{item.icons}</IconContainer>
                  <Typography fontWeight={700} variant="subtitle1">
                    {item.title}
                  </Typography>
                  <Typography align="center" fontWeight={300} variant="body2">
                    {item.desc}
                  </Typography>
                </ItemContainer>
              </AnimationOnScroll>
            </Stack>
          );
        })}
      </BodyContainer>
    </Container>
  );
};

export default BenefitsSection;
