import React, { useState } from "react";
import {
  Box,
  BoxProps,
  Button,
  Divider,
  Drawer,
  IconButton,
  Stack,
  Typography,
  styled,
} from "@mui/material";
import { IconLogoMedium } from "components/icons/Logos";
import CustomTooltip from "components/tooltip/tooltip";
import { NavLink, useNavigate } from "react-router-dom";
import "./Topbar_styles.css";
import { useTheme } from "layouts/theme/ThemeContext";
import HouseIcon from "@mui/icons-material/House";
import { useTranslation } from "react-i18next";
import LanguageSwitcher from "layouts/lang/language_switcher";
import BasicMenu from "./menu/BasicMenu";

const TopbarContent = styled(Box)<BoxProps>(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  height: "80px",
  paddingRight: "16px",
  paddingLeft: "16px",
  width: "100%",
  background:
    " linear-gradient(45deg, rgba(10,9,15,1) 0%, rgba(3,34,50,1) 30%, rgba(6,47,70,1) 68%, rgba(10,9,15,1) 100%)",
  boxShadow:
    "rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px, rgba(17, 17, 26, 0.1) 0px 24px 80px",
  /*  backgroundColor: theme.palette.primary.dark, */
  position: "fixed",
  top: 0,
  left: 0,
  zIndex: 1000,
  transition: "top 0.3s ease-in-out",
  "&.fixed": {
    position: "fixed",
    background: theme.palette.primary.main,
  },
}));

const BoxContent = styled(Box)<BoxProps>(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%",
  padding: "8px",
  height: "64px",
}));

const LogoContainer = styled(Stack)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: "6px",
  "&:hover": {
    transition: "all 0.2s ease-in-out",
    transform: "scale(1.1)",
  },
}));

const Topbar = () => {
  const { theme } = useTheme();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [tabOpen, setTabOpen] = useState<boolean>(false);

  const scrollToRef = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  const toPath = (navitate: string, id: string) => {
    navigate(`/${navitate}`);
    setTimeout(() => {
      scrollToRef(id);
    }, 50);
  };
  return (
    <TopbarContent>
      <BoxContent>
        <LogoIconButton
          onClick={() => {
            toPath("home", "homeRef");
          }}
          disableRipple
        >
          <LogoContainer>
            <IconLogoMedium
              fill={theme.palette.background.default}
              sx={{ width: "56px", height: "56px" }}
            />
            <Typography
              variant="h1"
              fontWeight={700}
              sx={{
                fontSize: "20px",
                color: theme.palette.background.paper,
              }}
            >
              robenice
            </Typography>
          </LogoContainer>
        </LogoIconButton>
        <Stack
          sx={{
            display: { xs: "none ", sm: "flex" },
          }}
          direction={"row"}
          gap={"8px"}
          alignItems={"center"}
        >
          <LinkButton
            onClick={() => {
              toPath("home", "aboutRef");
            }}
          >
            {t("topbar.about")}
          </LinkButton>
          <LinkButton
            onClick={() => {
              toPath("home", "projectRef");
            }}
          >
            {t("topbar.project")}
          </LinkButton>

          <BasicMenu
            title={t("topbar.RPA")}
            subTitles={[
              "topbar.whatisRPA",
              "topbar.rpaApplicationAreas",
              "topbar.areasOfUsage",
              "topbar.whyRobeniceRPA",
            ]}
          />
          <BasicMenu
            title={t("topbar.orchestrator")}
            subTitles={[
              "topbar.monitoring",
              "topbar.ioTmonitoring",
              "topbar.integration",
              "topbar.whyRobeniceOrchestrator",
            ]}
          />
          <LinkButton
            onClick={() => {
              toPath("home", "contactUsRef");
            }}
          >
            {t("topbar.contactUs")}
          </LinkButton>
        </Stack>

        <Stack
          sx={{ display: { xs: "none ", sm: "block" } }}
          direction={"row"}
          alignItems={"center"}
          gap={"8px"}
        >
          <LanguageSwitcher />
          {/*  <ThemeSwitcher /> */}
        </Stack>

        <Stack sx={{ display: { xs: "block ", md: "none" } }}>
          <Stack>
            <CustomTooltip title={"Top Menu"}>
              <IconButton
                onClick={() => {
                  setTabOpen(true);
                }}
              >
                <HouseIcon
                  sx={{
                    color: theme.palette.background.paper,
                    fontSize: "32px",
                  }}
                />
              </IconButton>
            </CustomTooltip>

            <Drawer
              variant="temporary"
              open={tabOpen}
              anchor={"right"}
              onClose={() => {
                setTabOpen(false);
              }}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
              sx={{
                display: { xs: "block", md: "none" },
                "& .MuiDrawer-paper": {
                  boxSizing: "border-box",
                  background:
                    " linear-gradient(45deg, rgba(10,9,15,1) 0%, rgba(3,34,50,1) 30%, rgba(6,47,70,1) 68%, rgba(10,9,15,1) 100%)",

                  width: "200px",
                },
              }}
            >
              <Stack marginTop={"24px"} padding={"16px"}>
                {tabOpen && (
                  <Typography
                    fontWeight={700}
                    variant="body1"
                    width={"100%"}
                    align="center"
                    sx={{
                      color: theme.palette.background.default,
                    }}
                  >
                    MENU
                  </Typography>
                )}
              </Stack>

              <Stack gap={"8px"} alignItems={"center"}>
                {/*    <NavLink
                  className={({ isActive }) => (isActive ? "exactactive" : "")}
                  to={"/home"}
                >
                  {t("topbar.home")}
                </NavLink>
                <NavLink
                  className={({ isActive }) => (isActive ? "exactactive" : "")}
                  to={"/home"}
                  onClick={() => {
                    scrollToRef("aboutRef");
                  }}
                >
                  {t("topbar.about")}
                </NavLink>
                <NavLink
                  className={({ isActive }) => (isActive ? "exactactive" : "")}
                  to={"/home"}
                  onClick={() => {
                    scrollToRef("projectRef");
                  }}
                >
                  {t("topbar.project")}
                </NavLink> */}
                <Stack
                  sx={{
                    color: theme.palette.background.default,
                    width: "120px",
                    height: "10px",
                    borderRadius: "4px",
                  }}
                />
                <LinkButton
                  onClick={() => {
                    toPath("home", "aboutRef");
                  }}
                >
                  {t("topbar.about")}
                </LinkButton>
                <LinkButton
                  onClick={() => {
                    toPath("home", "projectRef");
                  }}
                >
                  {t("topbar.project")}
                </LinkButton>
                <BasicMenu
                  title={t("topbar.RPA")}
                  subTitles={[
                    "topbar.whatisRPA",
                    "topbar.rpaApplicationAreas",
                    "topbar.areasOfUsage",
                    "topbar.whyRobeniceRPA",
                  ]}
                />
                <BasicMenu
                  title={t("topbar.orchestrator")}
                  subTitles={[
                    "topbar.monitoring",
                    "topbar.ioTmonitoring",
                    "topbar.integration",
                    "topbar.whyRobeniceOrchestrator",
                  ]}
                />
                {/*     <LinkButton
                  onClick={() => {
                    toPath("home", t("topbar.RPA"));
                  }}
                >
                  {t("topbar.RPA")}
                </LinkButton> */}
                {/*  <LinkButton
                  onClick={() => {
                    navigate(`/${t("topbar.orchestrator")}`);
                  }}
                >
                  {t("topbar.orchestrator")}
                </LinkButton> */}
                <LinkButton
                  onClick={() => {
                    toPath("home", "contactUsRef");
                  }}
                >
                  {t("topbar.contactUs")}
                </LinkButton>

                {/*  <NavLink
                  className={({ isActive }) => (isActive ? "exactactive" : "")}
                  to={"/home"}
                  onClick={() => {
                    scrollToRef("contactUsRef");
                  }}
                >
                  {t("topbar.contactUs")}
                </NavLink> */}
              </Stack>
            </Drawer>
          </Stack>
        </Stack>
      </BoxContent>
    </TopbarContent>
  );
};

export default Topbar;

export const LinkButton = styled(Button)(({ theme }) => ({
  ...theme.typography.body2,
  textTransform: "lowercase",
  fontWeight: 700,
  backgroundColor: "transparent",
  color: theme.palette.background.paper,
  "&:hover": {
    color: theme.palette.warning.dark,
    transition: "all 0.2s ease-in-out",
    transform: " scale(1.1)",
  },
}));

const LogoIconButton = styled(IconButton)(({ theme }) => ({
  "&:hover": {
    backgroundColor: "transparent",
  },
}));
