import { Stack, Typography, styled } from "@mui/material";
import React from "react";
import { AnimationOnScroll } from "react-animation-on-scroll";

const imgStyle = {
  width: "70%",
  height: "auto",
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
};

const HomeFooterContainer = styled(Stack)(({ theme }) => ({
  display: "flex",
  width: "100%",
  height: "90vh",
  marginTop: "48px",
  alignItems: "center",
  justifyContent: "center",

  [theme.breakpoints.down("md")]: {
    height: "60vh",
    marginTop: "0px",
  },
}));

const HomeFooter = () => {
  return (
    <HomeFooterContainer>
      <AnimationOnScroll animateIn="animate__fadeInUp" duration={0.65}>
        <Stack width={"100%"} alignItems={"center"} justifyContent={"center"}>
          <img style={imgStyle} alt="Home Image" src="media/home/home_1.png" />
        </Stack>
      </AnimationOnScroll>

      <Stack
        sx={{
          width: "70%",
          height: "24px",
          backgroundColor: "#BC5132",
          borderRadius: "0px 0px 8px 8px",
        }}
      ></Stack>
      {/*    <Typography
        sx={{ width: "85%" }}
        align="center"
        padding={"32px"}
        fontWeight={700}
        variant="body1"
      >
        Robenice Orchestrator & Automation ürünü ile amaçlanan müşterilerinize
        ait tüm alarm mekanizmalarını tek bir merkezde gözlemlemek, raporlarını
        otomatik oluşturmak ve oluşan tüm alarmlarda otomasyon ile sorunun
        çözümüne hızlı ulaşmak gibi ayrıcalıklarınız olacaktır.
        <br />
        <br />
        Ayrıca emsali olan UIPath ve Robusta gibi ürünlerin fazla maliyetlerini
        azaltacak, CRM, ITSM ürünleriniz ile de tüm süreçleri uçtan uca takip
        edebileceksiniz.
      </Typography> */}
    </HomeFooterContainer>
  );
};

export default HomeFooter;
