import { Stack, Typography, styled } from "@mui/material";
import { CustomTitle } from "components/components/CustomTitle";
import React from "react";
import { AnimationOnScroll } from "react-animation-on-scroll";

export const VisionContainer = styled(Stack)(({ theme }) => ({
  height: "50vh",
  width: "100%",
  padding: "60px",
  alignItems: "center",
  justifyContent: "center",
  gap: "60px",
  [theme.breakpoints.down("md")]: {
    padding: "120px 60px",
  },
}));

const ShadowTitle = styled(Typography)(({ theme }) => ({
  ...theme.typography.h2,
  fontWeight: 700,
  opacity: 0.2,
  height: "20px ",
  [theme.breakpoints.down("md")]: {
    ...theme.typography.h4,
    fontWeight: 700,
  },
}));

const Title = styled(Typography)(({ theme }) => ({
  ...theme.typography.h2,
  fontWeight: 700,
  [theme.breakpoints.down("md")]: {
    ...theme.typography.h4,
    fontWeight: 700,
  },
}));

const Text = styled(Typography)(({ theme }) => ({
  ...theme.typography.h6,
  maxWidth: "720px",
  [theme.breakpoints.down("md")]: {
    ...theme.typography.body2,
  },
}));

const HomeBodyType = ({
  shadowTitle,
  title,
  text,
}: {
  shadowTitle: string;
  title: string;
  text: string;
}) => {
  return (
    <>
      <AnimationOnScroll animateIn="animate__fadeInUp" duration={0.65}>
        <VisionContainer id="aboutRef">
          <CustomTitle shadowTitle={shadowTitle} title={title} />
          <Text align="center" dangerouslySetInnerHTML={{ __html: text }} />
        </VisionContainer>
      </AnimationOnScroll>
    </>
  );
};

export default HomeBodyType;
