import React from "react";
import { Grid, Stack, Typography, styled } from "@mui/material";
import {
  IconAnaliz,
  IconAnsible,
  IconBildirim,
  IconEntegrasyon,
  IconKullanım,
  IconMerkeziAlarm,
} from "components/icons/HomeIcon";
import { AnimationOnScroll } from "react-animation-on-scroll";
import "animate.css/animate.min.css";
import { CustomTitle } from "components/components/CustomTitle";

const Container = styled(Stack)(({ theme }) => ({
  width: "100%",
  justifyContent: "center",
  alignItems: "center",
  boxSizing: "border-box",
  padding: "16px",
  gap: "48px",
  [theme.breakpoints.down("md")]: {
    padding: "16px",
    gap: "24px",
  },
}));

const SubContainer = styled(Stack)(({ theme }) => ({
  width: "100%",
  justifyContent: "center",
  alignItems: "center",
  boxSizing: "border-box",
}));

const arr = [
  {
    icon: <IconAnsible sx={{ width: "58px", height: "58px" }} />,
    title: "Makine Öğrenmesiyle Merkezi Alarm Yönetimi",
  },
  {
    icon: <IconAnaliz sx={{ width: "58px", height: "58px" }} />,
    title: "Ayrıntılı Analiz ve Raporlama",
  },
  {
    icon: <IconBildirim sx={{ width: "58px", height: "58px" }} />,
    title: "Ayrıntılı Bildirim Seçenekleri",
  },
  {
    icon: <IconEntegrasyon sx={{ width: "58px", height: "58px" }} />,
    title: "Kolay Entegrasyon",
  },
  {
    icon: <IconKullanım sx={{ width: "58px", height: "58px" }} />,
    title: "Kolay Kullanım",
  },
  {
    icon: <IconMerkeziAlarm sx={{ width: "58px", height: "58px" }} />,
    title:
      "Yapay zeka destekli Ansible ve RPA Robotlar ile hızlı aksiyon ve sorun giderme",
  },
];

const ToolsContainer = styled(Stack)(({ theme }) => ({
  flex: "flex",
  gap: "12px",
  padding: "58px 150px",

  boxSizing: "border-box",
  [theme.breakpoints.between("xs", "sm")]: {
    alignItems: "center",
    padding: "24px 48px",
  },
  [theme.breakpoints.down("xs")]: {
    alignItems: "center",
    padding: "24px 32px",
  },
}));

const ArrContainer = styled(Stack)(({ theme }) => ({
  flex: "flex",
  gap: "12px",
  width: "100%",

  boxSizing: "border-box",
  [theme.breakpoints.down("md")]: {
    justifyContent: "center",
    alignItems: "center",
    padding: "8px",
    boxSizing: "border-box",
  },
}));

const ArrDesc = styled(Typography)(({ theme }) => ({
  ...theme.typography.subtitle2,
  paddingRight: "4px",
  [theme.breakpoints.down("md")]: {
    align: "center",
  },
}));

const SubContainerDesc = styled(Typography)(({ theme }) => ({
  fontWeight: 300,
  ...theme.typography.subtitle2,
  [theme.breakpoints.down("sm")]: {
    textAlign: "center",
  },
}));
const HomeDesing = () => {
  return (
    <Container>
      <SubContainer>
        <CustomTitle
          shadowTitle={"NEDEN ROBENİCE?"}
          title={"Neden Robenice ?"}
        />
        <SubContainerDesc>
          Proje Yönetiminde kusursuz bir deneyimi keşfedin
        </SubContainerDesc>
      </SubContainer>

      <Grid container justifyContent="center" alignItems="center">
        <Grid xs={12} md={6}>
          <AnimationOnScroll animateIn="animate__fadeInLeft" duration={0.65}>
            <ToolsContainer>
              <Typography fontWeight={400} variant="body1">
                Robenice Orchestrator & Automation ürünü ile amaçlanan
                müşterilerinize ait tüm alarm mekanizmalarını tek bir merkezde
                gözlemlemek, raporlarını otomatik oluşturmak ve oluşan tüm
                alarmlarda otomasyon ile sorunun çözümüne hızlı ulaşmak gibi
                ayrıcalıklarınız olacaktır.
                <br />
                <br />
                Ayrıca emsali olan yurtdışı menşei ürünlerin fazla maliyetlerini
                azaltacak, CRM, ITSM ürünleriniz ile de tüm süreçleri uçtan uca
                takip edebileceksiniz.
              </Typography>
            </ToolsContainer>
          </AnimationOnScroll>
        </Grid>
        <Grid xs={12} md={6}>
          <AnimationOnScroll animateIn="animate__fadeInRight" duration={0.65}>
            <HomeArr />
          </AnimationOnScroll>
        </Grid>
      </Grid>
    </Container>
  );
};

export default HomeDesing;

const HomeArr = () => {
  return (
    <Grid container justifyContent="center" alignItems="center">
      {arr.map((item, i) => {
        return (
          <Grid key={`HomeDesing${i}`} xs={8} md={6}>
            <ArrContainer>
              <Stack marginTop={"32px"} height={"58px"}>
                {item.icon}
              </Stack>
              <Typography
                fontWeight={700}
                paddingLeft={"4px"}
                variant="subtitle1"
                sx={{
                  textAlign: { xs: "center", md: "left" },
                }}
              >
                {item.title}
              </Typography>
            </ArrContainer>
          </Grid>
        );
      })}
    </Grid>
  );
};
