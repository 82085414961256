import React, { useRef } from "react";
import { Stack, Typography, styled } from "@mui/material";
import { projectInfoArr } from "../data/ProjectInfoArr";
import { AnimationOnScroll } from "react-animation-on-scroll";

export const CentralAlarmManagementContainer = styled(Stack)(({ theme }) => ({
  flex: "flex",
  display: "flex",
  height: "60vh",
  gap: "120px",
  padding: "60px ",
  boxSizing: "border-box",
  width: "100%",
  alignItems: "center",
  justifyContent: "center",
  [theme.breakpoints.down("md")]: {
    width: "100%",
    gap: "30px",
    height: "70vh",
  },
}));

export const CustomDivider = styled(Stack)(({ theme }) => ({
  width: "100px",
  height: "6px",
  borderRadius: "3px",
  backgroundColor: "#BC5132",
}));

const ProjectView = () => {
  return (
    <>
      {projectInfoArr.map((item, i) => {
        return (
          <CentralAlarmManagementContainer
            key={`centralAlarm${i}`}
            sx={{
              flexDirection: {
                xs: "column-reverse",
                md: item.status === false ? "row" : "row-reverse",
              },
            }}
          >
            <Stack flex={4}>
              <AnimationOnScroll
                animateIn="animate__fadeInLeft"
                duration={0.65}
              >
                <Stack
                  sx={{
                    alignItems: { xs: "center", md: "" },
                  }}
                  gap={"24px"}
                >
                  <Typography
                    sx={{
                      textAlign: { xs: "center", md: "left" },
                      fontWeight: 700,
                    }}
                    variant="h5"
                  >
                    {item.name}
                  </Typography>
                  <CustomDivider />
                  <Typography
                    sx={{
                      textAlign: { xs: "center", md: "left" },
                    }}
                    variant="body1"
                    dangerouslySetInnerHTML={{ __html: item.text }}
                  />
                </Stack>
              </AnimationOnScroll>
            </Stack>
            <Stack flex={5}>
              <AnimationOnScroll
                animateIn="animate__fadeInRight"
                duration={0.65}
              >
                <img
                  width={"100%"}
                  src={item.img}
                  data-base-url={item.imgLink}
                  data-extension=".png"
                  alt=""
                />
              </AnimationOnScroll>
            </Stack>
          </CentralAlarmManagementContainer>
        );
      })}
    </>
  );
};

export default ProjectView;
