import React from "react";
import { Stack, Typography, styled } from "@mui/material";
import { useTranslation } from "react-i18next";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { useTheme } from "layouts/theme/ThemeContext";
import { AnimationOnScroll } from "react-animation-on-scroll";
import { CustomTitle } from "components/components/CustomTitle";
const HeaderContainer = styled(Stack)(({ theme }) => ({
  width: "100%",
  height: "100vh",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  gap: "8px",
  padding: "0px 60px",
  backgroundImage: `url(media/rpa/RpaUsage.png)`,
  color: theme.palette.background.paper,
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "bottom",
  [theme.breakpoints.down("md")]: {
    justifyContent: "center",
    height: "100%",
    gap: "16px",
    flexDirection: "column",
    padding: "60px 24px",
  },
}));

const arrRPA = [
  "rpa.rpaUsageAreasDesc1",
  "rpa.rpaUsageAreasDesc2",
  "rpa.rpaUsageAreasDesc3",
  "rpa.rpaUsageAreasDesc4",
  "rpa.rpaUsageAreasDesc5",
  "rpa.rpaUsageAreasDesc6",
  "rpa.rpaUsageAreasDesc7",
  "rpa.rpaUsageAreasDesc8",
];

const RpaUsageAreas = () => {
  const { theme } = useTheme();
  const { t } = useTranslation();

  return (
    <HeaderContainer>
      <Stack gap="24px" flex={4}>
        <AnimationOnScroll
          animateIn="animate__fadeInLeft"
          duration={0.65}
          style={{ maxWidth: "100%" }}
        >
          <CustomTitle
            shadowTitle={t("rpa.rpaUsageAreas").toUpperCase()}
            title={t("rpa.rpaUsageAreas")}
          />
        </AnimationOnScroll>
        <Stack>
          {arrRPA.map((item, i) => {
            return (
              <AnimationOnScroll
                animateIn="animate__fadeInLeft"
                duration={0.65 * (i / 2)}
                style={{ maxWidth: "100%" }}
                key={`arrRPA_${i}`}
              >
                <Stack
                  direction={"row"}
                  sx={{
                    alignItems: "flex-start",
                    gap: "8px",
                  }}
                >
                  <FiberManualRecordIcon
                    sx={{
                      marginTop: "5px",
                      color: theme.palette.primary.main,
                      fontSize: "14px",
                    }}
                  />
                  <Typography
                    variant="body1"
                    sx={{
                      fontWeight: 700,
                    }}
                    dangerouslySetInnerHTML={{ __html: t(item) }}
                  />
                </Stack>
              </AnimationOnScroll>
            );
          })}
        </Stack>
      </Stack>
      <Stack flex={5}>
        {/*      <AnimationOnScroll animateIn="animate__fadeInRight" duration={0.85}>
          <img
            width={"100%"}
            src={"media/rpa/rpaUsageAreas.svg"}
            data-base-url={"media/rpa/rpaUsageAreas"}
            data-extension=".svg"
            alt=""
          />
        </AnimationOnScroll> */}
      </Stack>
    </HeaderContainer>
  );
};

export default RpaUsageAreas;
