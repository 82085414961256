import { Stack, Typography, styled } from "@mui/material";

const ShadowTitle = styled(Typography)(({ theme }) => ({
  ...theme.typography.h2,
  fontWeight: 700,
  opacity: 0.2,
  height: "20px ",
  [theme.breakpoints.down("md")]: {
    ...theme.typography.h4,
    textAlign: "center",
    fontWeight: 700,
  },
}));

const ShadowTitleShort = styled(Typography)(({ theme }) => ({
  ...theme.typography.h4,
  fontWeight: 700,
  opacity: 0.2,
  height: "5px ",
  [theme.breakpoints.down("md")]: {
    ...theme.typography.h5,
    textAlign: "center",
    fontWeight: 700,
  },
}));

const Title = styled(Typography)(({ theme }) => ({
  ...theme.typography.h2,
  fontWeight: 700,

  [theme.breakpoints.down("md")]: {
    ...theme.typography.h4,
    textAlign: "center",
    fontWeight: 700,
  },
}));

const TitleShort = styled(Typography)(({ theme }) => ({
  ...theme.typography.h4,
  fontWeight: 700,
  [theme.breakpoints.down("md")]: {
    ...theme.typography.h5,
    textAlign: "center",
    fontWeight: 700,
  },
}));

export const CustomTitle = ({
  shadowTitle,
  title,
}: {
  shadowTitle: string;
  title: string;
}) => {
  return (
    <Stack alignItems={"center"}>
      <ShadowTitle>{shadowTitle}</ShadowTitle>
      <Title>{title}</Title>
    </Stack>
  );
};

export const CustomTitleShort = ({
  shadowTitle,
  title,
}: {
  shadowTitle: string;
  title: string;
}) => {
  return (
    <Stack alignItems={"center"}>
      <ShadowTitleShort>{shadowTitle}</ShadowTitleShort>
      <TitleShort>{title}</TitleShort>
    </Stack>
  );
};
