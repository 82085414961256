import React, { useEffect, useRef, useState } from "react";
import { Button, Stack, Typography, alpha, styled } from "@mui/material";
import HomeHeader from "./components/HomeHeader";
import HomeFooter from "./components/HomeFooter";
import HomeDesing from "./components/HomeDesing";
import ContactView from "page/contactUs/components/ContactView";
import BenefitsSection from "./components/BenefitsSection";
import { AnimationOnScroll } from "react-animation-on-scroll";
import "animate.css/animate.min.css";
import ProjectView from "./components/ProjectView";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import HomeBodyType from "./components/HomeBodyType";
import { useTranslation } from "react-i18next";

export const CustomDivider = styled(Stack)(({ theme }) => ({
  width: "100px",
  height: "6px",
  borderRadius: "3px",
  backgroundColor: "#BC5132",
}));

export const TopButton = styled(Button)(({ theme }) => ({
  position: "fixed",
  zIndex: 120,
  bottom: "60px",
  right: "60px",
  width: "40px",
  minWidth: "40px",
  height: "48px",
  color: theme.palette.background.paper,
  backgroundColor: ` ${alpha(theme.palette.warning.dark, 0.5)} `,
  "&:hover": {
    color: theme.palette.background.paper,
    transition: "all 0.2s ease-in-out",
    transform: " scale(1.1)",
    backgroundColor: ` ${alpha(theme.palette.warning.dark, 0.8)} `,
  },
}));

export const VisionContainer = styled(Stack)(({ theme }) => ({
  height: "50vh",
  width: "100%",
  padding: "60px",
  alignItems: "center",
  justifyContent: "center",
  gap: "60px",
}));

export const MissionContainer = styled(Stack)(({ theme }) => ({
  height: "50vh",
  width: "100%",
  padding: "60px",
  alignItems: "center",
  justifyContent: "center",
  gap: "60px",
}));

const HomePage = () => {
  const { t } = useTranslation();
  const [showTopBtn, setShowTopBtn] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 400) {
        setShowTopBtn(true);
      } else {
        setShowTopBtn(false);
      }
    });
  }, []);

  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <Stack gap={"80px"}>
      {showTopBtn && (
        <TopButton onClick={goToTop}>
          <ArrowDropUpIcon sx={{ fontSize: "36px" }} />
        </TopButton>
      )}
      <HomeHeader />
      <Stack id="aboutRef" />
      <HomeBodyType
        shadowTitle={t("home.visionB")}
        title={t("home.visionS")}
        text={t("home.visionDesc")}
      />
      <HomeBodyType
        shadowTitle={t("home.missionB")}
        title={t("home.missionS")}
        text={t("home.missionDesc")}
      />
      <BenefitsSection />
      <Stack id="projectRef" />
      <HomeDesing />
      <ProjectView />
      <HomeFooter />
      <Stack id="contactUsRef">
        <ContactView />
      </Stack>
    </Stack>
  );
};

export default HomePage;
